
import React from 'react'
import "./Faq.sass"
import { graphql, useStaticQuery } from 'gatsby'

const GET_ALL_FAQ = graphql`
  {
    allMarkdownRemark(
      filter: {frontmatter: {templateKey: {eq: "faq"}}}, 
      sort: {fields: frontmatter___order}
    ) {
      edges {
        node {
          html
          frontmatter {
            title
          }
        }
      }
    }
  }
`

const FaqItem = ({ title, text }) => (
  <div className="faq-item">
    <h3>{title}</h3>
    <div dangerouslySetInnerHTML={{ __html: text }}></div>
  </div>
)

const Faq = () => {
  const { allMarkdownRemark } = useStaticQuery(GET_ALL_FAQ);
  const faqItems = allMarkdownRemark.edges.map((e) => ({ ...e.node }));
  const leftItems = faqItems.slice(0, 2);
  const rightItems = faqItems.slice(2);

  return (
    <section className="faq">
      <div className="content-container">
        <h2>Frequently asked questions</h2>
        <div className="column-container">
          <div className="column">
            {leftItems.map((item, i) => (
              <FaqItem key={i} title={item.frontmatter.title} text={item.html}/>
            ))}
          </div>
          <div className="column">
            {rightItems.map((item, i) => (
              <FaqItem key={i} title={item.frontmatter.title} text={item.html}/>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Faq;