
import React from 'react'
import PropTypes from 'prop-types'
import "../styles/Input.sass"

const Input = ({ placeholder, onChange }) => (
  <input 
    className="input"
    onChange={onChange}
    placeholder={placeholder}
  />
)

export default Input