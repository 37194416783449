
import React from 'react'
import "./CheckoutForm.sass"
import { CardElement } from 'react-stripe-elements'

class CheckoutForm extends React.Component {
  constructor (props) {
    super(props);
  }

  render () {
    return (
      <div className="checkout-form">
        <CardElement/>
      </div>
    )
  }
}

export default CheckoutForm;