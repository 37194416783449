import React from "react"
import "./Pricing.sass"
import { rootConfig } from "../../../../../shared/config"
import Button from "../shared/button/Button"
import { usePurchase } from "../purchase/hocs/usePurchase"
import GatsbyLink from "gatsby-link"
const checkmark = require("../../images/checkmark.svg")

const BookFeature = ({ text }) => (
  <div className="book-feature">
    <img src={checkmark} />
    <p>{text}</p>
  </div>
)

const Pricing = () => {
  const { operations, models } = usePurchase();
  console.log(models)

  return (
    <section id="pricing" className="pricing">
      <div className="content-container">
        <p id="pricing" className="title">
          Get the book
        </p>
        <h2>Pricing</h2>
        {models.purchaseQuantity === 1 && <h3 className="subtitle">
          {rootConfig.percentageOff}% Pre-sale Discount Until the Book is Fully
          Complete!
        </h3>}

        <div className="purchase-card">
          <div className="purchase-card-pricing-container">
            <p className="purchase-card-heading">FOR PROFESSIONAL DEVELOPERS</p>
            <div className="purchase-card-title">Book & wiki</div>
            <div className="purchase-card-price">
              {models.purchaseQuantity === 1 ? (
                <>
                  <p className="current-price">${rootConfig.price}</p>
                  <p className="original-price">{`$${rootConfig.originalPrice} USD`}</p>
                </>
              ) : (
                <>
                  <p className="current-price">Team License: {models.purchaseQuantity} copies</p>
                </>
              )}
              
            </div>
          </div>
          <div className="purchase-card-features-container">
            {[
              "350+ pages (and counting)",
              "Access to the online, live-updated wiki version of the book",
              "Lifetime access and updates to new versions",
              "PDF + EPUB",
            ].map((text, i) => (
              <BookFeature key={i} text={text} />
            ))}
            <br />
            <Button
              onClick={() => operations.openModal(models.purchaseQuantity, operations.getPricingFor(models.purchaseQuantity))}
              // text="Write Professional Code"
              text={models.purchaseQuantity !== 1 ? `Grab ${models.purchaseQuantity} copies for $${operations.getPricingFor(models.purchaseQuantity)}` : "Write better software"}
            />
          </div>
        </div>
        {models.purchaseQuantity === 1 && <div className="team-pricing">
            <p>Looking for team pricing? What's the size of your team?</p>
            <div className="team-prices">
              {[3, 5, 10, 15, 20, 25, 50, 100].map(val => (
                <GatsbyLink to={`/team/#?team_of=${val}`}>{val}</GatsbyLink>
              ))}
            </div>
          </div>
        }
      </div>
    </section>
  )
}

export default Pricing
