
import React from 'react'
import Loader from 'react-loader-spinner'
import "./Modal.sass"

interface ModalProps {
  isOpen: boolean;
  loading: boolean;
  children: any;
  onClose: Function;
}

const Modal: React.FC<ModalProps> = (props) => (
  <section 
    className={`modal ${props.isOpen ? 'open' : ''} ${props.loading ? 'loading' : ''}`}
    onClick={() => props.onClose()}>
      {props.loading ? (
        <Loader
          type="Puff"
          //@ts-ignore
          color="#7DE77B"
          height="100"
          width="100"
        />
      ) : ''}

      <div 
        className="modal-container" 
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {props.children}
      </div>
  </section>
)

export default Modal;