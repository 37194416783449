
import React from 'react'
import "./Footer.sass"

const Footer = () => (
  <section className="footer">
    <div className="links">
      <a href="https://khalilstemmler.com">Blog</a>
      <a href="mailto:khalil@khalilstemmler.com">Contact</a>
      <a href="https://twitter.com/stemmlerjs">Twitter</a>
    </div>
    <div className="copyright">© {new Date().getFullYear()}, Khalil Stemmler</div>
  </section>
)

export default Footer;