
import React from 'react'
import "./Author.sass"
import { graphql, useStaticQuery } from 'gatsby'
const me = require('../../images/me.png');

const GET_AUTHOR_DETAILS = graphql`
  {
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "author"}}}) {
      edges {
        node {
          html
        }
      }
    }
  }
`

const Author = () => {
  const { allMarkdownRemark } = useStaticQuery(GET_AUTHOR_DETAILS);
  const authorContent = allMarkdownRemark.edges.map((e) => ({ ...e.node}))[0];
  return (
    <section className="author">
      <div className="content-container">
        <div className="column">
          <img className="author-img" src={me}/>
        </div>
        <div className="column">
          <h2>About The Author</h2>
          <div dangerouslySetInnerHTML={{ __html: authorContent.html }}></div>
        </div>
      </div>
    </section>
  )
}

export default Author;